// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../src/pages/about_us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-programs-angelbot-js": () => import("./../src/pages/programs/angelbot.js" /* webpackChunkName: "component---src-pages-programs-angelbot-js" */),
  "component---src-pages-programs-js": () => import("./../src/pages/programs.js" /* webpackChunkName: "component---src-pages-programs-js" */),
  "component---src-pages-programs-wings-js": () => import("./../src/pages/programs/wings.js" /* webpackChunkName: "component---src-pages-programs-wings-js" */),
  "component---src-pages-terms-js": () => import("./../src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */)
}

