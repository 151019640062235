module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"typekit":{"id":"rtx2vjd"}},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-158909295-1"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"AngelShot Ltd","short_name":"AngelShot Ltd","start_url":"/","background_color":"#754995","theme_color":"#754995","display":"standalone","icon":"static/favicon/android-chrome-192x192.png","icons":[{"src":"static/favicon/android-chrome-192x192.png","type":"image/png","sizes":"192x192"},{"src":"static/favicon/android-chrome-512x512.png","sizes":"512x512","type":"image/png"}],"crossOrigin":"use-credentials"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
